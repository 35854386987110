.oem-form {
  label {
    font-size: 13px;
  }
  input,
  .ant-select-selector {
    font-size: 12px;
    padding: 0.5rem;
  }
  .form-title {
    font-weight: bold;
    font-size: 16px;
    color: var(--cl1);
  }
  .footer {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 1px solid var(--cl6);
    .save-button {
      margin-right: 1rem;
      .ant-btn-loading-icon {
        padding-left: 2rem;
      }
      .title {
        padding-right: 2rem;
      }
    }
    .save-button:not(.ant-btn-loading) {
      .title {
        padding-left: 2rem;
      }
    }
  }
}
