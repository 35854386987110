.livestream-form {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  .product-card {
    display: flex;
    border-radius: 10px;
    width: 100%;
    border: 1px solid #e0e0e0;
    padding: 8px;
    gap: 20px;
    cursor: pointer;
    &:hover {
      border: 1px solid var(--cl1);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      transform: scale(1.02);
    }

    .product-name {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .product-categories {
      font-size: 12px;
      color: #a1a1a1;
    }
  }
}
