.search-producers {
  display: flex;
  gap: 2rem;
  align-items: center;
  .ant-input-affix-wrapper {
    width: 80%;
  }
  .search-label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.84);
  }
}

.ant-modal-content {
  border-radius: 5px;
}
