.customized-btn {
  margin-right: 1rem;
  .title {
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    border: 3px solid var(--cl1);
    border-radius: 5px;
    transition: all 300ms ease;
  }
  .title:hover {
    background-color: var(--cl13);
  }
}
