.security-page {
  .security-section {
    padding: 8px 0;
    &__header {
      font-weight: bold;
    }
  }

  ul {
    padding-left: 4px;
    li {
      list-style: none;
      margin-bottom: 4px;
    }
  }
}
