.purchase-order-information {
  .company-image {
    margin-right: 0.5rem;
  }
  .certificate-image {
    padding: 0.25rem;
  }
  .information-form {
    .certificates {
      .list-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .video-url-wrapper {
      .group:not(:first-child) {
        margin-top: 0.5rem;
      }
    }
    .news-url-wrapper {
      .group:not(:first-child) {
        margin-top: 0.5rem;
      }
    }
    .distributed-channel {
      .url {
        padding-left: 0.5rem;
      }
      .group:not(:first-child) {
        margin-top: 0.5rem;
      }
    }
    .company-images {
      display: flex;
      justify-content: flex-start;
    }
    .company-image-count {
      color: var(--cl1);
    }
    .ant-form-item-explain,
    .ant-form-item-extra {
      font-size: 12px;
    }
    .ant-form-item-label > label {
      font-size: 13px !important;
    }
    input {
      font-size: 12px;
      padding: 0.5rem;
    }
    .ant-select-selector {
      padding: 0.1rem 0.5rem;
      font-size: 12px;
    }
    .ant-input-affix-wrapper {
      padding: 0;
    }
    textarea.ant-input {
      font-size: 12px;
    }
    .ant-form-item-label > label {
      font-size: 14px;
    }
    .ant-radio-button-wrapper {
      height: 100%;
      border-radius: 8px;
      border-left-width: 1px;
      width: 7.5rem;
      padding: 1rem;
      width: 160px;
      .business-type-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .name {
          color: var(--cl2);
          font-size: 12px;
        }
      }
      .business-type-item:hover {
        .name {
          color: var(--cl1);
        }
      }
    }
    .ant-radio-button-wrapper:not(:first-child) {
      margin-left: 1rem;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      width: 0;
    }
  }
}

.remove-button-wrapper {
  display: grid;
  place-items: center;
}

.remove-button {
  box-shadow: none;
  border: none;
  width: 1rem;
  height: 1rem;
  display: flex;
  margin-left: -0.5rem;
  padding: 0;
  justify-content: center;
  align-items: center;
  svg {
    transition: none;
    width: 60% !important;
  }
}

.plus-button-wrapper {
  display: flex;
  align-items: center;
}
.plus-button {
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: none;
    width: 60% !important;
  }
}
.add-image-button-wrapper {
  padding-top: 1rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
}

.add-image-button {
  display: flex;
  align-items: center;
  font-size: 12px;
  height: fit-content;
  padding: 2px 7px;
  svg {
    transition: none;
    margin-right: 0.25rem;
  }
}

.purchase-order-information {
  .label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    height: 32px;
    align-items: center;
  }

  .footer {
    padding-bottom: 12px;
  }
}

.order-items-required {
  .ant-form-item-control {
    .ant-form-item-control-input {
      display: none;
    }
  }
}
