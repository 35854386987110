.page-title {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  display: flex;
  align-items: center;
}
.page-title .line {
  width: 5rem;
  border-top: 6px solid var(--cl6);
  border-radius: 8px;
  margin-left: 1.5rem;
}

.fullpage {
  background-color: #fff;
  .page-title {
    padding: 1rem 5rem 0 5rem;
  }
  .faq-content-wrapper {
    margin-top: 0;
    border-radius: 0;
    padding: 1rem 5rem;
  }
}
.login-header-wrapper {
  background-color: var(--cl1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.login-header {
  font-size: 12px;
  padding: 0.5rem 5rem;
  display: flex;
  justify-content: space-between;
}
.login-wrapper {
  background-color: #fff;
}
.logo-wrapper {
  display: flex;
  cursor: pointer;
  .link-news {
    margin-left: 1.5rem;
    display: grid;
    place-items: center;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    a {
      padding: 0.5rem;
    }
  }
  .link-news a:hover {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
  }
}
.faqs-pagination {
  display: flex;
  justify-content: flex-end;
}
