.dashboard-step {
  .ant-steps-icon {
    font-weight: bold;
  }
  .ant-steps-item-icon {
    margin: 0 !important;
  }
}
.steps-wrapper {
  margin: 1rem 0 !important;
  .ant-steps-item {
    padding-left: 0 !important;
  }
  .ant-steps-item-title {
    padding-right: 0;
    width: 9999px;
    position: absolute;
    border-top: 4px dashed var(--cl6);
    top: 10px;
  }
  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    content: none;
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: var(--cl1);
    }
    .ant-steps-item-icon svg {
      fill: #fff;
    }
  }
  .ant-steps-item-finish .ant-steps-item-title {
    border-top-style: solid;
    border-color: var(--cl1);
  }
}
.step-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1em;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox,
  .ant-checkbox-input,
  .ant-checkbox-wrapper {
    cursor: default;
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: var(--cl6);
  }
}
.modal-new-account-intro {
  min-width: 30vw;
  .ant-modal-content {
    border-radius: 24px;
  }
  .new-account-intro-image {
    height: 315px;
  }
  .slider-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      font-weight: 500;
      font-size: 24px;
      text-align: center;
      color: var(--cl1);
    }
    .description {
      font-size: 16px;
      text-align: center;
      color: #4f4f4f;
      font-weight: normal;
    }
  }
  .slick-slider .slick-prev {
    left: 0 !important;
    z-index: 999;
    width: auto;
    color: var(--cl1);
    top: 97%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .slick-slider .slick-next {
    right: 0 !important;
    z-index: 999;
    width: auto;
    color: var(--cl1);
    top: 97%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .finish-intro-button {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 999;
    border-radius: 15px;
  }
  .slick-list {
    margin-bottom: 2rem;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-slide img {
    transform: scale(0.85);
  }
}
