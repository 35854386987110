.login-container {
  .agreement {
    margin: 1rem 0;
  }

  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:focus,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    border-color: var(--cl5) !important;
    &:focus {
      border-top-color: transparent !important;
    }
  }

  .ant-form-item-children-icon {
    transition: none !important;
  }
  .register-btn {
    border: 1px solid var(--cl1) !important;
    background: var(--cl1) !important;
    color: white;
    height: 2.5rem;
    width: 100%;
    border-radius: 8px;
    transition: all 0.3s;
    height: 2.5rem;
    span {
      font-weight: 700;
      font-size: 14px;
    }
  }
  .upload-form-item {
    .ant-form-item-label {
      margin-top: 8px;
    }
  }
}

.upload-center {
  text-align: center;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  color: #fff;
  input {
    padding: 0 0.25rem;
    font-size: 12px;
    margin: 0.25rem 0;
  }
  .remember-wrapper {
    font-size: 12px;
    color: #fff;
  }
  .remember-wrapper:hover {
    border-color: #fff;
  }
  a:hover {
    color: #fff;
    text-decoration: underline;
  }
  .login-btn {
    padding: 0 0.25rem;
    height: auto;
    font-size: 12px;
    background-color: var(--cl1);
    color: #fff;
    border-color: #fff;
    font-weight: bold;
  }
  .register-btn {
    padding: 0 0.25rem;
    height: auto;
    font-size: 12px;
    background-color: var(--cl1);
    color: #fff;
    border-color: #fff;
    font-weight: bold;
  }
  .title {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .form-item {
    margin-right: 0.5rem;
  }
}

.login-btn-wrapper {
  justify-content: flex-start;
  align-items: baseline;
  .error-text {
    margin-bottom: 0.25rem;
    color: #fcfff7;
    font-weight: 700;
  }
}

@media (max-width: 879.98px) {
  .login-form {
    margin-top: 16px;
    .form-group {
      input {
        padding: 0.25rem;
      }
      margin-left: 0;
      .form-item {
        flex-direction: column;
      }
      .login-btn {
        width: 100px;
        padding: 0.25rem;
        margin: 4px 0;
      }
    }
  }
}

@media (max-width: 576.98px) {
  .login-form {
    .form-group {
      .form-item {
        flex-direction: row;
      }
    }
  }
}
