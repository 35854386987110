.user-container {
  .page-title {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    display: flex;
    align-items: center;
    .line {
      width: 5rem;
      border-top: 6px solid var(--cl6);
      border-radius: 8px;
      margin-left: 1.5rem;
    }
  }
  .page-content {
    margin-top: 1rem;
    .right-wrapper {
      .right {
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        .sub-menus {
          display: flex;
          border-bottom: 1px solid var(--cl6);
          .item {
            padding: 2rem 1rem 0 1rem;
            cursor: pointer;
            color: var(--cl5);
            font-weight: 500;
            .active {
              color: var(--cl1);
              font-weight: bold;
            }
            .border-bottom {
              height: 4px;
              width: 2rem;
              border-radius: 8px;
              &.active {
                background-color: var(--cl1);
              }
            }
          }
          .item:not(:first-child) {
            margin-left: 2rem;
          }
        }
      }
    }
  }
  .content {
    padding: 1rem 1rem 0 1rem;
  }
  .footer {
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 1px solid var(--cl6);
    .save-button {
      margin-right: 1rem;
      .ant-btn-loading-icon {
        padding-left: 2rem;
      }
      .title {
        padding-right: 2rem;
      }
    }
    .save-button:not(.ant-btn-loading) {
      .title {
        padding-left: 2rem;
      }
    }
  }
}
