.category-posts-section {
  .category-posts {
    background-color: #fff;
    padding: 1rem;
    .subcates {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
      .title {
        text-transform: uppercase;
        text-align: center;
      }
      .item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #4f4f4f;
        .border-bottom {
          border: 2px solid var(--cl1);
          width: 100%;
          border-radius: 8px;
        }
      }
      .title {
        padding: 0.5rem 1rem;
        background-color: var(--cl7);
        color: #fff;
        font-weight: 900;
      }
      .item:not(.title) {
        margin: 0 1rem 0 0;
      }
      .active {
        color: var(--cl1);
      }
    }
    .blog-wrapper {
      padding: 0.5rem;
    }
    .blog {
      cursor: pointer;
      border: 1px solid var(--cl9);
      display: flex;
      flex-direction: row;
      overflow: hidden;
      flex-wrap: nowrap;
      height: 86px;
    }
    .banner-wrapper {
      width: 140px;
      background: #c4c4c4;
      overflow: hidden;
    }
    .banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .blog:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      .title {
        color: var(--cl7);
      }
    }
    .blog:hover .banner {
      transform: scale(1.05);
      transition: transform ease-in 0.3s;
    }
    .blog-meta {
      font-weight: 600;
      padding: 1rem;
      width: 60%;
      display: flex;
      align-items: center;
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: none;
        -webkit-transition: none;
        font-size: 14px;
        font-weight: 500;
        color: #4f4f4f;
      }
    }
  }
  .menu {
    margin-bottom: 0;
    padding: 0;
    .menu-item {
      margin: 0;
      min-width: 34px;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      a {
        margin: 0;
        padding: 0.25rem 0.5rem;
      }
    }
    .menu-item-active {
      background-color: var(--cl12);
    }
    .menu-item:hover {
      background-color: var(--cl12);
    }
  }
  .posts-pagination {
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
  }
}
.fullpage {
  .menu-wrapper {
    background-color: #fff;
    margin-top: 0;
    padding: 0 5rem 0 5rem;
  }
  .menu {
    margin-top: 0;
    .menu-item-active {
      background-color: unset;
      a {
        background-color: var(--cl12);
        color: #fff;
        border-radius: 5px;
      }
    }
    .menu-item:hover {
      background-color: unset;
      a {
        color: var(--cl1);
      }
    }
    .menu-item-active:hover {
      a {
        color: #fff;
      }
    }
  }
  .category-posts {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  .posts-pagination {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
