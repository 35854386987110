.service-register-status-select {
  font-size: 12px;
}
.view-register-service-button {
  font-size: 12px;
  padding: 0;
  height: fit-content;
  padding: 0.25rem;
  font-weight: 500;
}
