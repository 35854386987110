.banner-blog-widget {
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  .banner {
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  .blog-meta {
    position: absolute;
    bottom: 0;
    padding: 1rem;
    z-index: 2;
    color: #fff;
    .title {
      line-height: normal;
    }
    .description {
      margin-top: 0.5rem;
    }
    .author {
      font-size: 12px;
      margin-right: 1rem;
    }
    .date {
      font-size: 12px;
    }
  }
}

.banner-blog-widget:hover .banner {
  transform: scale(1.05);
  transition: transform ease-in 0.3s;
}

.banner-blog-with-meta::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70%;
  z-index: 1;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
}
