.transport-service-modal {
  .group-list {
    .ant-col {
      border: 1px solid;
      border-bottom: none;
      border-right: none;
      padding-right: 0;
      display: flex;
      align-items: center;
      margin-left: -1px;
      margin-top: -1px;
      .ant-input {
        border-radius: 0;
        height: 100%;
      }
    }
    span {
      padding: 0.25rem 1rem;
    }
    .ant-col:last-child {
      padding-left: 0;
    }
  }
}
