.sales {
  .page-title {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    display: flex;
    align-items: center;
    .line {
      width: 5rem;
      border-top: 6px solid var(--cl6);
      border-radius: 8px;
      margin-left: 1.5rem;
    }
  }
  .page-content {
    margin-top: 1rem;
    padding: 1rem 2rem;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      color: var(--cl1);
    }
    .tips {
      color: var(--cl1);
    }
    .group {
      margin-top: 1rem;
      .title {
        font-weight: bold;
        font-size: 14px;
        color: #4f4f4f;
        margin-bottom: 0.5rem;
      }
      .channels {
        .channel-wrapper {
          cursor: pointer;
          padding: 0 1rem 1rem 0;
          .register-status {
            position: absolute;
            top: 0.5rem;
            right: 1.5rem;
            font-weight: 500;
            font-size: 10px;
            text-align: center;
            padding: 0.15rem 0.4rem;
            color: #ffbe00;
            background: rgba(255, 190, 0, 0.15);
            border-radius: 11px;
          }
          .approved {
            color: var(--cl1);
            background: #e6f4ed;
            border-radius: 11px;
          }
        }
        .channel {
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          background: #ffffff;
          padding: 2rem 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          .more-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1.25rem;
            font-weight: bold;
            text-align: center;
            color: var(--cl4);
            .border-bottom {
              border: 2px solid var(--cl4);
              border-radius: 32px;
              width: 30%;
            }
          }
        }
        .channel-wrapper:hover {
          .more-info {
            margin-top: 1.25rem;
            font-weight: bold;
            text-align: center;
            color: var(--cl1);
            .border-bottom {
              border: 2px solid var(--cl1);
              border-radius: 32px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.modal-channel-wrapper {
  .ant-modal-body {
    padding: 3rem;
  }
}
.channel-modal {
  .channel-logo-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 0 1.5rem 0;
  }
  .channel-description {
    font-weight: 500;
    font-size: 12px;
    color: #4f4f4f;
  }
  .conditions-title-wrapper {
    margin: 0.75rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      font-weight: bold;
      font-size: 14px;
      color: var(--cl1);
    }
    .border-bottom {
      margin-top: 0.25rem;
      border: 1px solid var(--cl1);
      width: 3rem;
      border-radius: 16px;
    }
  }
  .conditions-descriptions {
    font-size: 12px;
    color: #4f4f4f;
  }
  .conditions {
    display: flex;
    flex-direction: column;

    .ant-checkbox-wrapper {
      margin-top: 0.5rem;
      margin-left: 1rem;
      font-size: 12px;
      font-weight: 500;
      color: #4f4f4f;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 1rem;
    }
    .ant-checkbox,
    .ant-checkbox-checked,
    .ant-checkbox-input,
    .ant-checkbox-inner {
      border-radius: 100%;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after {
      border-radius: 100%;
      border-color: var(--cl5);
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: var(--cl1);
    }
  }
  .register-button-wrapper {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    .register-button {
      padding: 0 3rem;
    }
    .channel-registered-message {
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: var(--cl1);
      background: #e6f4ed;
      border-radius: 6px;
      padding: 0.25rem 2rem;
    }
  }
}
