@import '~react-quill/dist/quill.snow.css';
@import '~react-quill/dist/quill.bubble.css';

:root {
  --body-bg-color: #f6f6f6;
  --body-font: 'Roboto', sans-serif;
  --body-color: var(--cl8);
  --body-line-height: 1.42857143;
  --h1: 36px;
  --h2: 30px;
  --h3: 24px;
  --h4: 20px;
  --h5: 18px;
  --h6: 16px;
  --cl2: #2d2e32;
  --cl3: #f5222d;
  --cl4: #828282;
  --cl5: #bdbdbd;
  --cl6: #e0e0e0;
  --cl8: #373737;
  --cl9: #e6e6e6;
  --cl16: black;
  --warning-color: #faad14;
  --error-color: #f5222d;
}
[data-theme='DTrack'] {
  --cl1: #029147;
  --cl7: #0a923c;
  --cl10: rgba(7, 149, 33, 0.2);
  --cl11: rgba(2, 145, 71, 0.1);
  --cl12: #147c41;
  --cl13: rgb(76 175 80 / 8%);
  --cl15: #029147;
  --cl17: #d5e8dc;
}
[data-theme='VietFish'] {
  --cl1: #0f3258;
  --cl7: #2e8bc0;
  --cl10: rgba(8, 133, 235, 0.2);
  --cl11: rgba(2, 62, 173, 0.1);
  --cl12: #0370b5;
  --cl13: rgb(33 150 243 / 8%);
  --cl14: #0370b5;
  --cl15: #4dadbf;
}
[data-theme='Foodmap'] {
  --cl1: #029147;
  --cl7: #0a923c;
  --cl10: rgba(7, 149, 33, 0.2);
  --cl11: rgba(2, 145, 71, 0.1);
  --cl12: #147c41;
  --cl13: rgb(76 175 80 / 8%);
  --cl15: #029147;
  --cl17: #d5e8dc;
}
html {
  overflow-y: scroll;
  font-size: 14px;
}
a:hover {
  color: var(--cl1);
}
.vnd {
  margin-right: 0.25rem;
  font-size: 12px;
}

.success-message {
  color: var(--cl1) !important;
}
.error-message {
  color: var(--cl3);
}
.upload-image-error {
  color: var(--cl3);
  font-size: 12px;
}
.remove-button-wrapper {
  display: flex;
  align-items: center;
}
.remove-button {
  box-shadow: none;
  border: none;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.remove-button svg {
  transition: none;
  width: 60% !important;
}
.plus-button-wrapper {
  display: flex;
  align-items: center;
}
.plus-button {
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plus-button svg {
  transition: none;
  width: 60% !important;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  transform: rotate(180deg);
}

.no-trans * {
  transition: all 0s ease;
  -webkit-transition: all 0s ease;
}
.sec-box-white {
  overflow: visible !important;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 6%);
  box-shadow: 0 0 10px rgb(0 0 0 / 6%);
}
.hidden {
  display: none;
}
.lg\:block {
  @media (min-width: 768px) {
    display: block;
  }
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml--5 {
  margin-left: -5px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-40 {
  margin-left: 40px;
}

.fw-bold {
  font-weight: bold;
}
.fs-15 {
  font-size: 15px;
}
.text-align-right {
  text-align: right;
}

.primary-section-title {
  color: var(--cl12) !important;
  margin-bottom: 8px;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1186px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1186px;
  }
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
ul.actions {
  list-style-type: none;
  li {
    float: left;
    margin-right: 10px;
    a.btn {
      background-color: var(--cl1);
      border-radius: 5px;
      padding: 2px 5px;
      min-width: 100px;
      color: #fff;
      &.--edit {
        background-color: var(--warning-color);
      }
      &.--delete {
        background-color: var(--error-color);
      }
    }
  }
}

.livestream-form-drawer .ant-drawer-content-wrapper {
  @media (min-width: 768px) {
    min-width: 768px !important;
  }

  @media (min-width: 991px) {
    min-width: 991px !important;
  }

  @media (min-width: 1200px) {
    min-width: 1000px !important;
  }
}

.bg-white {
  background-color: #fff !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.livestream-event-page {
  h1 {
    line-height: 1.05;
  }

  img {
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .btc {
    img {
      border-radius: 0;
      box-shadow: none;
    }
  }

  .main {
    margin-bottom: 32px;

    .header-wrapper {
      background-color: rgb(246, 246, 246);

      .header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;

        @media (max-width: 991px) {
          padding-bottom: 0 !important;
        }

        .header-left {
          .title {
            font-size: 36px;
            color: var(--cl1);
            font-weight: 700;
          }
          .description {
            font-size: 1.25rem;
            line-height: 1.75rem;
            text-transform: uppercase;
            color: var(--text);
          }
        }

        .header-right {
          img {
            width: 100%;
          }

          @media (min-width: 991px) {
            img {
              margin-top: 50px;
              margin-bottom: 50px;
            }
          }
        }

        @media (min-width: 991px) {
          flex-direction: row;

          .header-right {
            width: 630px;
          }
        }
      }
    }
  }
  .section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .section-title {
      color: var(--cl1);
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      text-align: center;
    }
  }

  .reverse {
    flex-direction: column-reverse;
  }

  .section-title {
    text-align: center !important;
    font-size: 1.75rem !important;
    font-weight: bold;
    color: var(--cl1) !important;

    @media (min-width: 991px) {
      font-size: 2rem !important;
    }
  }

  @media (min-width: 991px) {
    .section {
      flex-direction: row;
      .section-title {
        text-align: left;
      }
    }
  }

  .btc {
    background-color: rgb(230, 244, 237, 0.7);
    padding: 20px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;

    .group {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 991px) {
    .btc {
      flex-direction: row;
    }
  }

  .slider {
    @media (max-width: 991px) {
      display: none !important;
    }
  }

  .slider-2 {
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 20px 0;
    position: relative;
    mask-image: linear-gradient(to right, transparent, black 2%, black 98%, transparent);
    -webkit-mask-image: linear-gradient(to right, transparent, black 2%, black 98%, transparent);

    // @media (min-width: 991px) {
    //   display: none;
    // }

    &-wrapper {
      position: relative;
      padding: 0 40px;

      @media (max-width: 768px) {
        padding: 0 10px;
        .slider-images {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    }

    &-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.9);
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: all 0.2s;

      &:hover {
        background: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      }

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }

      svg {
        width: 14px;
        height: 14px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .slider-2::-webkit-scrollbar {
    display: none;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .slider {
    margin: auto;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 20px;
    padding-left: 250px;
    padding-right: 250px;
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .slider-arrow--next {
    transform: translateX(3rem) !important;
  }

  .slider-arrow--prev {
    transform: translateX(-3rem) !important;
  }
}

.tet-fest {
  .header-right {
    @media (min-width: 1024px) {
      img {
        width: 600px !important;
        margin-bottom: 32px;
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }

    @media (min-width: 1400px) {
      img {
        width: 800px !important;
        margin-bottom: 32px;
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }
  }

  .register-button {
    background-color: #5150f3 !important;
    border: #5150f3;
    font-weight: bold;
    color: white;
    height: 48px;

    &:hover,
    &:focus,
    &:active {
      color: white !important;
    }
  }

  .slider-desc {
    text-align: center;
    font-style: italic;
    font-size: 17px;
    color: var(--cl3);
    margin-bottom: 4px;
    padding-top: 10px;
    font-weight: 500;

    @media (min-width: 991px) {
      padding-top: 20px;
    }
  }

  .section-title {
    text-align: center !important;
    font-size: 1.7rem !important;
    font-weight: bold;
    color: var(--cl3) !important;
  }

  .group {
    display: flex;
    flex-direction: column;
    .subgroup {
      display: flex;
      gap: 16px;
      @media (min-width: 1024px) {
        gap: 20px;
      }
    }
    img {
      max-height: 45px;
    }
    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  @media (max-width: 991px) {
    .header-left {
      h1 {
        font-size: 24px !important;
      }
    }

    .section {
      padding-top: 0;
      padding-bottom: 20px;
    }
  }

  @media (min-width: 991px) {
    .main-content {
      padding: 0px 32px;
    }

    .slider-2 {
      img {
        height: 350px !important;
      }
      padding-left: 10px;
      padding-right: 10px;
    }

    .section-title {
      font-size: 2rem !important;
    }
  }
}

.mekong-connect {
  .mekong-slider {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      flex-shrink: 0;
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: 4px;
    }

    @media (min-width: 991px) {
      flex-direction: row;

      img {
        width: 33%;
      }
    }
  }

  @media (max-width: 991px) {
    .section.reverse {
      padding-top: 0;
      padding-bottom: 0;
    }
    .register-section {
      padding-top: 0;
    }
  }

  .mekong-section-slider {
    @media (max-width: 990.99px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    @media (min-width: 991px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;

      img {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }

  .register-section {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: flex-end;

    margin-top: 32px;

    @media (min-width: 991px) {
      margin-top: 0;
    }

    .register-wrapper {
      background-color: rgb(230, 244, 237, 0.7);
      padding: 8px 20px;
      margin: auto;
      border-radius: 12px;
    }
  }

  .mekong-btc {
    .btc {
      justify-content: center;
    }
  }
}

.lg\:hidden {
  @media (min-width: 768px) {
    display: none;
  }
}

.ldbl {
  .header-left {
    .title {
      color: var(--cl3) !important;
    }
  }

  .section-title {
    color: var(--cl3) !important;
  }

  #register-button {
    background-color: #5150f3 !important;
    border: #5150f3;
    font-weight: bold;
    color: white;
    height: 48px;

    &:hover,
    &:focus,
    &:active {
      color: white !important;
    }
  }

  .event-info {
    background-color: rgb(245, 34, 45, 0.15);
    padding: 20px;
    border-radius: 12px;
  }

  .shopee-info {
    background-color: rgb(255, 236, 61, 0.15);
    padding: 20px;
    border-radius: 12px;
  }

  .mekong-slider {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      flex-shrink: 0;
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: 4px;
    }

    @media (min-width: 991px) {
      flex-direction: row;

      img {
        width: 33%;
      }
    }
  }

  @media (max-width: 991px) {
    .section.reverse {
      padding-top: 0;
      padding-bottom: 0;
    }
    .register-section {
      padding-top: 0;
    }
  }

  .mekong-section-slider {
    @media (max-width: 990.99px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    @media (min-width: 991px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;

      img {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }

  .register-section {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: flex-end;

    margin-top: 32px;

    @media (min-width: 991px) {
      margin-top: 0;
    }

    .register-wrapper {
      background-color: rgb(230, 244, 237, 0.7);
      padding: 8px 20px;
      margin: auto;
      border-radius: 12px;
    }
  }

  .btc {
    justify-content: center;
    img {
      height: 40px;
    }
  }
}
