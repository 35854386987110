@import '../../../styles/slick-slide.scss';

.login-page {
  height: 100vh;
  .login-wrapper {
    background-color: #fff;
  }
  .login-form,
  .login-section {
    display: flex;
  }
  .login-body-wrapper {
    background-color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .intro-wrapper {
      .slick-slider {
        width: 600px;
        margin: auto;
      }
    }
    .intro-title {
      font-weight: 500;
      font-size: 22px;
      line-height: 47px;
      color: var(--cl2);
    }
    .intro-description {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      color: var(--cl2);
    }
    .signup-form-wrapper {
      .title {
        font-weight: 500;
        font-size: 30px;
        line-height: 59px;
        color: var(--cl1);
      }
      .description {
        color: var(--cl2);
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
      }
      .agreement {
        font-size: 12px;
        margin-top: 1rem;
      }
      .policy {
        color: var(--cl1);
        font-weight: 500;
      }
      .register-btn {
        width: 100%;
      }
      .footer {
        margin-top: 1rem;
      }
    }

    .slick-slider .slick-prev {
      left: 0 !important;
      z-index: 999;
      line-height: 30px;
      border-radius: 100%;
      width: 2rem;
      height: 2rem;
      padding: 8px;
      color: var(--cl1);
      top: 40%;
    }
    .slick-slider .slick-next {
      right: 0 !important;
      z-index: 999;
      line-height: 30px;
      border-radius: 100%;
      width: 2rem;
      height: 2rem;
      padding: 8px;
      color: var(--cl1);
      top: 40%;
    }
  }
  .sign-up-text {
    cursor: pointer;
    text-decoration: underline;
  }
  .error-text {
    color: var(--cl3);
    font-weight: 500;
  }
  .success-text {
    color: var(--cl1);
    font-weight: 500;
  }
  .slider-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      font-weight: 500;
      font-size: 22px;
      line-height: 37px;
      text-align: center;
      color: var(--cl1);
    }
    .description {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      color: var(--cl2);
    }
    img {
      height: 400px;
    }
  }

  .slick-slide img {
    transform: scale(0.85);
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #fff !important;
  }
}

@media only screen and (min-width: 992px) {
  .login-page {
    display: flex;
    flex-direction: column;
    .login-body-wrapper {
      flex: 1;
    }
    .login-section.login-section-failure {
      transform: translateX(-40%);
      .error-text {
        position: absolute;
        top: 2px;
        right: -165px;
      }
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .login-page {
    .login-section {
      flex-direction: column;
      .error-text {
        margin-left: 16px;
      }
    }
  }
}
