.register-department-container {
  padding: 1rem 0;
  .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 59px;
    color: var(--cl1);
    text-align: center;
  }
  .error-text {
    color: var(--cl3);
    font-weight: 600;
  }
  .success-text {
    color: var(--cl1);
    font-weight: 600;
  }
}

.register-department-container.page-content {
  margin-top: 1rem;
  padding: 1rem 2rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
}
