.language-menu {
  font-family: 'Roboto';
  & > ul {
    list-style: none;
    padding: 0;
  }

  $orange: #fff;
  $blue: #2980b9;
  $gray: #eee;

  @mixin ul-nostyle {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  @mixin double-shadow($color) {
    box-shadow: 0 1px 0 lighten($color, 10%) inset, 0 -1px 0 darken($color, 10%) inset;
  }

  @mixin hover-style($color) {
    &:hover {
      background: lighten($color, 3%);
    }
  }

  @mixin animation($content) {
    animation: $content;
    -moz-animation: $content;
    -webkit-animation: $content;
  }

  @mixin keyframes($name) {
    @keyframes #{$name} {
      @content;
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @-webkit-keyframes #{$name} {
      @content;
    }
  }

  // =============================================================================
  //	Classes
  // =============================================================================
  .dropdown {
    position: relative;

    a {
      text-decoration: none;
    }

    [data-toggle='dropdown'] {
      @include hover-style($orange);
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 4px 15px;
      height: 2.5rem;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: var(--cl2);
      display: flex;
      align-items: center;
      &:before {
        position: absolute;
        display: block;
        content: '\25BC';
        font-size: 0.7em;
        color: var(--cl5);
        right: 10px;
        transform: rotate(0deg);
        transition: transform 0.6s;
      }
      .flag {
        margin-right: 0.5rem;
      }
    }

    & > .dropdown-menu {
      max-height: 0;
      overflow: hidden;
      @include ul-nostyle;
      transform: scaleY(0);
      transition: max-height 0.6s ease-out;
      @include animation(hideAnimation 0.4s ease-out);
      background: #ffffff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      .flag {
        margin-right: 0.5rem;
      }
      li {
        padding: 0;
        padding: 6px 15px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--cl2);
        display: flex;
      }
      li:hover {
        background-color: rgba(189, 189, 189, 0.15);
      }
    }

    & > input[type='checkbox'] {
      opacity: 0;
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:checked ~ .dropdown-menu {
        max-height: 9999px;
        display: block;
        transform: scaleY(1);
        @include animation(showAnimation 0.5s ease-in-out);
        transition: max-height 2s ease-in-out;
      }

      &:checked + a[data-toggle='dropdown'] {
        &:before {
          transform: rotate(-180deg);
          transition: transform 0.6s;
        }
      }
    }
  }

  @include keyframes(showAnimation) {
    0% {
      transform: scaleY(0.1);
    }
    40% {
      transform: scaleY(1.04);
    }
    60% {
      transform: scaleY(0.98);
    }
    80% {
      transform: scaleY(1.04);
    }
    100% {
      transform: scaleY(0.98);
    }
    80% {
      transform: scaleY(1.02);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @include keyframes(hideAnimation) {
    0% {
      transform: scaleY(1);
    }
    60% {
      transform: scaleY(0.98);
    }
    80% {
      transform: scaleY(1.02);
    }
    100% {
      transform: scaleY(0);
    }
  }
}
