.producer-qr-code {
  .qr-code-tips {
    color: var(--cl1);
  }
  .producer-qr-code-left {
    padding-right: 3.5rem;
  }
  .producer-qr-code-right {
    margin-top: 4rem;
    padding-right: 3.5rem;
  }
  .title {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 24px;
    color: var(--cl1);
  }
  .description {
    margin-bottom: 0.5rem;
    color: var(--cl2);
  }
  .qr-wrapper {
    margin-top: 1.5rem;
    .edit-qrcode {
      margin-left: 1rem;
      margin-right: 0.25rem;
      color: #fff;
      background-color: var(--cl1);
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      border-radius: 12px;
      font-size: 12px;
    }
    .title {
      font-size: 14px;
      color: var(--cl2);
    }
    .qr-code-wrapper {
      margin: 1rem 0;
      display: flex;
      justify-content: center;
      .qr-code {
        width: 256px;
        padding: 2rem;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        canvas {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
  .link-wrapper {
    margin-top: 1.5rem;
    margin-bottom: 3.5rem;
    .title {
      font-size: 14px;
      color: var(--cl2);
    }
    .ant-input-group-addon {
      background-color: var(--cl2);
    }
    button {
      background-color: var(--cl2);
      border-color: var(--cl2);
    }
  }
}
.download-qr-code-button {
  margin-left: 1rem;
}
.modal-qrcode {
  width: 50vw !important;
}

.sales-container {
  .plus-button-wrapper {
    position: absolute;
    right: 0;
    transform: translate(100%, 0);
  }
}

.edit-qrcode-form {
  .color-picker {
    margin-top: 0.5rem;
  }
  .download-wrapper {
    display: flex;
    justify-content: center;
  }
  .qr-code {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
  .field-item {
    margin-bottom: 0.5rem;
    .include-logo-checkbox {
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #d9d9d9 !important;
      }
      .ant-checkbox:hover {
        .ant-checkbox-input + .ant-checkbox-inner {
          border-color: #d9d9d9 !important;
        }
      }
    }
    .title {
      margin-right: 0.5rem;
    }
    .title-wrapper {
      display: flex;
    }
    .picked-color-wrapper {
      width: fit-content;
      padding: 0.2rem;
      border: 1px solid #000;
    }
    .picked-color {
      width: 2rem;
      height: 1rem;
    }
    input {
      width: 50%;
    }
  }
  .qrcode-warning {
    font-style: italic;
    font-size: 12px;
    font-weight: 700;
  }
}

@media (max-width: 768px) {
  .modal-qrcode {
    width: 100vw !important;
  }
  .producer-qr-code-left {
    padding-right: 0 !important;
  }
  .producer-qr-code-right {
    margin-top: 1rem !important;
    padding-right: 0 !important;
  }
}
