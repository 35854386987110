:root {
  --green: rgba(2, 145, 71, 1);
  --gray: rgba(246, 246, 246, 1);
  --text: #2d2e32;
  --lightgreen: #e6f4ed;
}

.dtrack-services {
  .arrow-left-inside {
    transform: translate(70px, -20px);
  }
  .arrow-right-inside {
    transform: translate(-70px, -20px);
  }
  .expandable::before {
    content: '';
    width: 100%;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(#f9fbfb00 50%, #f9fbfb);
  }
  .expanded {
    max-height: 999999px;
  }
  .collapsed {
    max-height: 1000px;
  }
  .green-dot-list li {
    display: flex;
    align-items: baseline;
  }
  .green-dot-list li::before {
    content: '•';
    color: green;
    display: inline-flex;
    align-items: center;
    font-size: 24px;
    width: 0.8rem;
    margin-left: -0.5em;
  }

  .header__title {
    font-size: 36px;
    color: #029147;
    font-weight: bold;
  }
  .header__desc {
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    color: #2d2e32;
  }
  .header__buttons {
    margin-top: 35px;
    display: flex;
  }
  .header__image {
    position: relative;
    height: 210px;
  }
  .card__no-border {
    padding: 0 !important;
  }
  .card__price-banner {
    width: 280px;
    .banner {
      height: 155px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .card__small-border {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .body__maxWidth {
    max-width: 988px;
  }
  .pricing__header {
    font-size: 32px;
    line-height: 37px;
  }
  .pricing__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pricing__subtitle {
    font-weight: 700;
    font-size: 17px;
    color: white;
    text-transform: uppercase;
    background: var(--green);
    border-radius: 22px;
    padding: 0;
  }
  .pricing__card-wrapper {
    display: flex;
  }
  .pricing__card {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
  }
  .pricing__card__image {
    width: 150px;
    height: 150px;
  }
  .pricing__two-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    row-gap: 16px;
    .pricing__card-wrapper {
      flex: 1 1 33%;
      justify-content: center;
      .pricing__card {
        height: 100%;
      }
    }
  }
  .slider__leftside div {
    height: auto;
  }
  .slider__key {
    height: 50px !important;
    width: 90px !important;
  }
  .slider__image {
    height: 472px;
  }
  .product-slider {
    margin: auto;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 20px;
  }
  .steps__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--green);
    text-align: center;
  }
  .step {
    width: 16rem;
    width: 100%;
    text-align: center;
    line-height: 2em;
    border-radius: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 200px;
    justify-items: center;
    padding-bottom: 2rem;
    color: white;
    position: relative;
    overflow: hidden;
    .step__image {
      width: 200px;
      height: 200px;
      position: relative;
    }
    .step__dot {
      display: none;
    }
    .step__desc {
      max-width: 200px;
      padding-top: 16px;
      color: black;
    }
  }
  .price-banner {
    font-weight: 700;
    color: white;
    background: var(--green);
    border-radius: 1rem;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  }
  .brands {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.25rem;
    .brand {
      width: 100px;
      height: 100px;
    }
  }
  .footer {
    padding-bottom: 0.75rem;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    color: var(--green);
    max-width: 790px;
    margin: auto;
  }
  .youtube-icon {
    background: transparent;
    border: 0;
    cursor: pointer;
    width: 68px;
    height: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:hover svg path:nth-of-type(1) {
      fill: red;
      fill-opacity: 1;
    }
  }
  .video-iframe {
    width: 720px;
    height: 480px;
  }
  .btn {
    background: #029147;
    border-radius: 22px;
    border: 0;
    cursor: pointer;
    padding: 0 15px;
    height: 44px;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    &.--outline {
      background: white;
      color: #029147;
      border: 1px solid #069347;
    }
  }

  .partner-footer-text {
    max-width: 360px;
  }
}

.dtrack-index {
  .top-slider {
    background-color: #f6f6f6;
  }
  .top-slider__desc {
    max-width: 663px;
    margin: auto;
    color: var(--text);
    text-transform: uppercase;
  }
  .top-slider__slider-item {
    width: 386px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .founder__slogan {
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
  }
  .brands {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.25rem;
    .brand {
      width: 100px;
      height: 100px;
    }
  }
  .footer__title {
    max-width: 724px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    text-align: center;
    color: white;
    font-size: 32px;
    line-height: 37px;
    padding-bottom: 2rem;
  }
  .footer__consultation-form {
    background: white;
    border-radius: 1rem;
    padding: 1.5rem;
    --shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(0 0 #0000, 0 0 #0000), var(0 0 #0000, 0 0 #0000), var(--shadow);
  }
  .footer__background {
    max-width: 100%;
  }
  .btn {
    background: #029147;
    border-radius: 22px;
    border: 0;
    cursor: pointer;
    padding: 0 24px;
    height: 44px;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    &.--outline {
      background: white;
      color: #029147;
      border: 1px solid #069347;
    }
  }
}

.slick-dots {
  bottom: -25px !important;
}

@media (min-width: 768px) {
  .dtrack-services {
    .steps__title {
      font-size: 32px;
      line-height: 36px;
    }
    .partner-footer-text {
      max-width: 700px;
    }
  }
  .dtrack-index {
    .brands {
      .brand {
        width: 100px;
        height: 100px;
      }
    }
  }
}

@media (min-width: 992px) {
  .dtrack-services {
    .header__image {
      height: 416px;
    }
    .pricing__card {
      width: 280px;
      &--small {
        width: 220px;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }
    .step {
      width: auto;
      grid-template-columns: none;
      grid-template-rows: 216px 17px auto;
      .step__dot {
        display: flex;
        svg {
          position: relative;
          z-index: 3;
        }
      }
      .step__dot::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(2px);
        width: 9999px;
        border-top: 2px dashed rgba(2, 145, 71, 0.25);
      }
      .step__dot::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        width: 9999px;
        transform: translate(-100%, 2px);
        border-top: 2px dashed rgba(2, 145, 71, 0.25);
      }
    }
    .step:first-child .step__dot::after {
      border-top: solid 2px #e6f4ed;
    }

    .step:last-child .step__dot::before {
      border-top: solid 2px #e6f4ed;
    }
    .product-slider {
      padding-left: 150px;
      padding-right: 150px;
    }
    .btn {
      font-size: 20px;
    }
  }
  .dtrack-index {
    .founder__desc {
      max-width: 506;
    }
  }
  .slider-arrow {
    z-index: 10;
    background: var(--green) !important;
    font-size: 24px;
    width: 35px;
    height: 35px;
    display: inline-grid !important;
    place-items: center;
    border-radius: 100%;
    svg {
      path {
        fill: white;
      }
    }
    &--next {
      svg {
        transform: translateX(2px);
      }
    }
    &--prev {
      svg {
        transform: translateX(-2px);
      }
    }
  }
}

@media (min-width: 1280px) {
  .dtrack-services {
    .pricing__content {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
}
