.box-content {
  border-radius: 3px;
  .title {
    background: var(--cl7);
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 35px;
  }
  .box-content-body {
    position: relative;
    padding: 15px;
    background: #fff;
    color: #000;
  }
}
