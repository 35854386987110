// @import "../pages/forgot-password/style";
//global
@import './404.scss';
@import './common.scss';
@import './globals.css';
//hoc
@import '../hoc/withPageContent/styles.scss';
//components
@import '../components/containers/account-setup/styles.scss';
@import '../components/containers/dashboard/styles.scss';
@import '../components/containers/faqs/styles.scss';
@import '../components/containers/forgot-password/styles.scss';
@import '../components/containers/information-security/styles.scss';
@import '../components/containers/login/styles.scss';
@import '../components/containers/news/styles.scss';
@import '../components/containers/register-department/styles.scss';
@import '../components/containers/reset/styles.scss';
@import '../components/containers/sales/styles.scss';
@import '../components/containers/services/styles.scss';
@import '../components/containers/services/VietfishModalServices/styles.scss';
@import '../components/containers/services/VietfishModalServices/ads/styles.scss';
@import '../components/containers/services/VietfishModalServices/ElectricityAndWater/styles.scss';
@import '../components/containers/services/VietfishModalServices/ExternalContractorElectricity/styles.scss';
@import '../components/containers/services/VietfishModalServices/Freezer/styles.scss';
@import '../components/containers/services/VietfishModalServices/materials/styles.scss';
@import '../components/containers/services/VietfishModalServices/seminar/styles.scss';
@import '../components/containers/services/VietfishModalServices/transport/styles.scss';
@import '../components/containers/user-services/styles.scss';
@import '../components/containers/productBatches/qrcode.scss';
@import '../components/containers/news/styles.scss';
@import '../components/containers/news/categories/categories.scss';

@import '../components/AdminContainers/services-approval/styles.scss';
@import '../components/AdminContainers/producers/styles.scss';
@import '../components/AdminContainers/users/styles.scss';
@import '../components/AdminContainers/pageViews/styles.scss';

@import '../components/BannerBlogWidget/styles.scss';
@import '../components/BannerButton/styles.scss';
@import '../components/BoxContent/styles.scss';
@import '../components/BoxTabBlog/styles.scss';
@import '../components/CustomizedButton/styles.scss';
@import '../components/ConsultationForm/styles.scss';
@import '../components/DefaultLayout/styles.scss';
@import '../components/Editor/styles.scss';
@import '../components/Footer/styles.scss';
@import '../components/FormInput/styles.scss';
@import '../components/IframeService/styles.scss';
@import '../components/LanguagesMenu/styles.scss';
@import '../components/Loading/styles.scss';
@import '../components/LoginHeader/styles.scss';
@import '../components/TextField/styles.scss';
@import '../components/VoteApproval/styles.scss';
@import '../components/ProducerForm/styles.scss';
@import '../components/ProductForm/product.scss';
@import '../components/UploadImage/styles.scss';
@import '../components/UserForm/styles.scss';
@import '../components/OemForm/styles.scss';
@import '../components/ProductBox/styles.scss';
@import '../components/PurchaseOrderForm/styles.scss';
@import '../components/ServiceRegisterForm/styles.scss';
@import '../components/LivestreamGuide/styles.scss';
@import '../components/LivestreamForm/styles.scss';

@import '../pages/services/style.scss';
@import '../pages/services/utilities.scss';
