.user-services-container {
  .header-title {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    .line {
      width: 5rem;
      border-top: 6px solid var(--cl6);
      border-radius: 8px;
      margin-left: 1.5rem;
    }
  }
  .service {
    margin-top: 8px;
    margin-right: 16px;
    .service-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 8px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: var(--cl1);
    }
    .step {
      display: grid;
      place-items: center;
      .step-title {
        color: white;
        display: grid;
        place-items: center;
        text-align: center;
        border-radius: 100%;
        width: 120px;
        height: 120px;
        font-size: 18px;
        font-weight: 500;
        padding: 8px;
      }
      .complete {
        path {
          fill: var(--cl1);
        }
      }
    }
  }
}
.modal-channel-wrapper {
  .ant-modal-body {
    padding: 3rem;
  }
}
.channel-modal {
  .channel-logo-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 0 1.5rem 0;
  }
  .channel-description {
    font-weight: 500;
    font-size: 12px;
    color: #4f4f4f;
  }
  .conditions-title-wrapper {
    margin: 0.75rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      font-weight: bold;
      font-size: 14px;
      color: var(--cl1);
    }
    .border-bottom {
      margin-top: 0.25rem;
      border: 1px solid var(--cl1);
      width: 3rem;
      border-radius: 16px;
    }
  }
  .conditions-descriptions {
    font-size: 12px;
    color: #4f4f4f;
  }
  .conditions {
    display: flex;
    flex-direction: column;

    .ant-checkbox-wrapper {
      margin-top: 0.5rem;
      margin-left: 1rem;
      font-size: 12px;
      font-weight: 500;
      color: #4f4f4f;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 1rem;
    }
    .ant-checkbox,
    .ant-checkbox-checked,
    .ant-checkbox-input,
    .ant-checkbox-inner {
      border-radius: 100%;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after {
      border-radius: 100%;
      border-color: var(--cl5);
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: var(--cl1);
    }
  }
  .register-button-wrapper {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    .register-button {
      padding: 0 3rem;
    }
    .channel-registered-message {
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: var(--cl1);
      background: #e6f4ed;
      border-radius: 6px;
      padding: 0.25rem 2rem;
    }
  }
}
