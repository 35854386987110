.vietfish-service-modal {
  padding-bottom: 0;
  .modal-title {
    text-align: center;
    color: #fff;
    .title {
      font-size: 18px;
    }
    .date {
      font-size: 13px;
    }
  }
  .ant-modal-header {
    background: var(--cl1);
    border-radius: 5px 5px 0px 0px;
  }
  .ant-modal-content {
    border-radius: 5px;
  }
  .ant-modal-body {
    background: #e8ecee;
    border-radius: 0px 0px 5px 5px;
    padding: 0;
    overflow: hidden;
    .content {
      font-weight: 600;
      font-size: 15px;
      line-height: 23px;
      color: #1d1e20;
      text-transform: capitalize;
    }
  }
  .sub-menus {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 1rem;
    border-bottom: 1px solid var(--cl6);
  }
  .sub-menu-item {
    cursor: pointer;
    text-align: center;
    transition: all 0.01ms;
  }
  .step-text {
    font-size: 14px;
    font-weight: 900;
    transition: all 0.01ms;
    text-transform: capitalize;
  }
  .menu-item {
    color: var(--cl5);
  }
  .menu-item-active {
    color: var(--cl1);
  }
  .step-border-bottom {
    margin-top: 0.25rem;
    height: 4px;
    background-color: var(--cl6);
  }
  .step-border-bottom-active {
    background-color: var(--cl1);
  }
  .footer {
    border-radius: 0px 0px 5px 5px;
    background: #fff;
    margin: 0 -10px -10px;
    padding-bottom: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  .footer > button {
    min-width: 10em;
  }
  .footer > button {
    margin-right: 1rem;
  }
  .text-term {
    color: var(--cl1);
    min-height: 32px;
    cursor: pointer;
  }
  .terms-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .ant-form-item-control-input {
      align-items: baseline;
    }
  }
  .ml--100 {
    margin-left: -100px;
  }
  .ant-modal-footer {
    border-top: none;
  }
  //render tabinfo
  .container-content {
    overflow: auto;
    background: #fff;
    padding: 1rem 2.5rem 1rem 2.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 1px;
    height: 450px;
    overflow: auto;
    .content-title {
      text-align: center;
      font-size: 32px;
    }
    .content-widget {
      margin: 0 3rem 0;
      text-align: justify;
      li {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    b {
      font-weight: 900;
    }
    .text {
      font-style: italic;
      font-weight: 800;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 20px;
      }
    }
    .note {
      font-size: 16px;
      text-decoration: underline;
    }
  }
  .children {
    font-size: 14px;
    text-align: justify;
    .note {
      font-size: 16px;
      text-decoration: underline;
    }
    .text-color-red {
      color: var(--cl3);
    }
    li {
      margin-left: 15px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
    }

    b {
      font-weight: 900;
    }
    p {
      b {
        padding: 0 0.25rem;
      }
    }
  }
  .slick-slider .slick-prev {
    left: 3% !important;
    z-index: 999;
    line-height: 30px;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    padding: 8px;
    color: var(--cl1);
    top: 40%;
  }
  .slick-slider .slick-next {
    right: 3% !important;
    z-index: 999;
    line-height: 30px;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    padding: 8px;
    color: var(--cl1);
    top: 40%;
  }
  .slider {
    .slick-list {
      margin: 0;
    }
    margin: 0 3rem 0;
    .slider-item {
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        height: 400px;
      }
    }
  }
  .group-list {
    .ant-col {
      border-bottom: none;
      border-right: none;
      padding-right: 1rem;
    }
  }
  .show-border-bottom {
    border-bottom: 1px solid !important;
  }
  .show-border-right {
    border-right: 1px solid !important;
  }
}

.h-auto {
  height: auto !important;
}

//render modal 1 tab
.container-modal {
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 !important;
  margin: -4px -4px 0 -4px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
  height: 450px;
}

[data-theme='VietFish'] {
  .vietfish-service-modal {
    .ant-modal-header {
      background: var(--cl15);
    }
    .menu-item-active {
      color: var(--cl15);
    }
    .step-border-bottom-active {
      background-color: var(--cl15);
    }
  }
}
