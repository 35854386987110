.page-title {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  display: flex;
  align-items: center;
}
.page-title .line {
  width: 5rem;
  border-top: 6px solid var(--cl6);
  border-radius: 8px;
  margin-left: 1.5rem;
}
.menu {
  margin: 1rem 0;
  background: var(--cl1);
  padding: 0;
  .menu-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    a {
      padding: 0.25rem 0.5rem;
      margin: 0;
    }
  }
  .ant-menu-item-active {
    color: black;
  }
  .menu-item:hover {
    background-color: var(--cl12);
  }
  svg {
    cursor: pointer;
    fill: #fff;
  }
  a {
    margin-left: 1rem;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
  }
  .active {
    background-color: var(--cl7);
    color: #fff;
    padding: 3px 5px;
    text-transform: uppercase;
    margin-left: 1rem;
    font-size: 15px;
  }
}

.news-section {
  .banner-blogs {
    display: flex;
    margin-bottom: 1rem;
    height: 378px;
    .left-side {
      float: left;
      width: 50%;
      padding-right: 0.25rem;
      height: 100%;
    }
    .right-side {
      float: left;
      width: 50%;
      padding-left: 0.25rem;
      height: 100%;
      .top-side {
        padding-bottom: 0.25rem;
        height: 50%;
      }
      .bottom-side {
        height: 50%;
        padding-top: 0.25rem;
      }
    }
  }
}

.post-section {
  .menu {
    margin-bottom: 0;
  }
  .post-wrapper {
    background-color: #fff;
    padding: 2rem;
    justify-content: center;
    .feature-image {
      max-height: 400px;
      max-width: 100%;
      background: #c4c4c4;
      margin: 0 auto;
    }
    .title {
      font-weight: bold;
      font-size: 24px;
      color: #4f4f4f;
      margin-top: 0.5rem;
    }
    .composed-by {
      margin: 0.5rem 0;
      font-weight: normal;
      font-size: 12px;
      color: #4f4f4f;
      .author {
        font-weight: bold;
      }
    }
    .post-content-html {
      height: auto;
      padding: 0;
      img {
        max-width: 100%;
      }
      white-space: normal;
    }
  }
}
.fullpage {
  background-color: #fff;
  .page-title {
    padding: 1rem 5rem 0 5rem;
  }
  .menu-wrapper {
    margin-top: 0;
    padding: 0 5rem;
  }
  .menu {
    margin-top: 0;
    background-color: unset;
    .menu-item:hover {
      background-color: unset;
      a {
        color: var(--cl1);
      }
    }
    svg {
      fill: var(--cl1);
    }
    a {
      color: var(--cl1);
    }
    .active {
      color: var(--cl1);
    }
  }
  section {
    padding: 0 5rem 0 5rem;
  }
  section {
    padding-bottom: 1rem;
  }
  .banner-blogs {
    padding-bottom: 0;
  }
}
.login-header-wrapper {
  background-color: var(--cl1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.login-header {
  font-size: 12px;
  padding: 0.5rem 5rem;
  display: flex;
  justify-content: space-between;
}
.login-wrapper {
  background-color: #fff;
}
.logo-wrapper {
  display: flex;
  cursor: pointer;
  img {
    width: 64px !important;
  }
  .link-news {
    margin-left: 1.5rem;
    display: grid;
    place-items: center;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    a {
      padding: 0.5rem;
    }
  }
  .link-news a:hover {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
  }
}
