.materials-service-modal {
  .group-list {
    .ant-col {
      border: 1px solid;
      border-bottom: none;
      border-right: none;
      padding-right: 0;
      display: flex;
      align-items: center;
      margin-left: -1px;
      margin-top: -1px;
      .ant-input {
        border-radius: 0;
        height: 100%;
      }
    }
    span {
      padding: 0.25rem 1rem;
    }
    .ant-col:last-child {
      padding-left: 15px;
    }
    .ant-input {
      padding: 4px 15px;
    }
    .border-right-left-none {
      border-left: 0;
      border-right: 0;
    }
    .border-right-none {
      border-right: 0;
    }
    .border-left-none {
      border-left: 0;
    }
    .justify-center {
      justify-content: center;
    }
    .mr--02 {
      margin-right: -0.2%;
    }
    .max-width-total {
      max-width: 24.9%;
    }
  }
}
