.services-container {
  flex: 1;
  .page-title {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    display: flex;
    align-items: center;
    .line {
      width: 5rem;
      border-top: 6px solid var(--cl6);
      border-radius: 8px;
      margin-left: 1.5rem;
    }
  }
  .back-button {
    margin-top: 1rem;
  }
  .page-content {
    padding-top: 1rem;
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      color: var(--cl1);
    }
    .section-title {
      font-weight: 500;
      font-size: 24px;
      color: #000000;
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      .line {
        width: 5rem;
        border-top: 6px solid var(--cl6);
        border-radius: 8px;
        margin-left: 1.5rem;
      }
    }
    .services {
      margin-top: 1rem;
      width: 100%;
      .more-info:hover {
        margin-top: 1.25rem;
        font-weight: bold;
        text-align: center;
        color: var(--cl1);
        .border-bottom {
          border: 2px solid var(--cl1);
          border-radius: 32px;
          width: 100%;
        }
      }
    }
    .register-services {
      margin-top: 0;
    }
  }
}

.modal-design-boxing {
  width: 70vw !important;
  .ant-modal-footer {
    border-top: none;
  }
}
.modal-marketing {
  width: 50vw !important;
  .ant-modal-header {
    border-bottom: none;
  }
  .logo {
    display: grid;
    place-content: center;
    img {
      height: 50px;
    }
  }
  .marketing-information {
    padding: 1rem 2rem;
    .marketing-content-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: var(--cl1);
    }
    .marketing-content-story {
      margin: 1rem 0;
      text-align: center;
      .story-image {
        img {
          width: 100%;
        }
      }
    }
    .marketing-content-images {
      margin: 1rem 0;
      .image-wrapper {
        img {
          width: -webkit-fill-available;
        }
      }
    }
    .marketing-content-images ul li {
      margin: 0;
    }
    .marketing-content-images ul li button::before {
      color: var(--cl1);
      opacity: 0.5;
    }
    .marketing-content-images ul li.slick-active button::before {
      background: var(--cl1);
      border-radius: 10px;
      width: 36px;
      height: 4px;
      content: '';
      top: 8px;
      opacity: 1;
    }
    .marketing-content-images ul li.slick-active {
      width: 36px;
    }
    .marketing-content-images ul li.slick-active button {
      width: 36px;
    }
    .marketing-content-images ul {
      bottom: -15px;
    }
  }
}
.modal-capital-support {
  width: 50vw !important;
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-footer {
    border-top: none;
  }
  .capital-support-information {
    padding: 1rem 2rem;
    .capital-support-content {
      margin-left: 2rem;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      color: var(--cl2);
    }
  }
  .ant-checkbox-wrapper {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: var(--cl1);
  }
  .ant-checkbox-inner {
    border-radius: 100%;
  }
  .modal-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: var(--cl1);
  }
  .divider {
    width: 3rem;
    height: 2px;
    background-color: var(--cl1);
  }
}
.modal-certificate {
  width: 50vw !important;
  .modal-title {
    text-align: center;
    font-weight: normal;
    color: hsl(228, 5%, 19%);
  }
  .certificates-wrapper {
    padding-bottom: 1rem;
  }
  .certificate-item {
    width: 120px;
    height: 120px;
    cursor: pointer;
    display: grid;
    place-content: center;
    border: 1px solid var(--cl6);
    border-radius: 6px;
    padding: 1.5rem;
    margin-left: 1rem;
    margin-top: 1rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .certificate-item-active {
    border-color: var(--cl1);
  }
  .certificate-item:hover {
    border-color: var(--cl1);
  }
  .certificate-information {
    padding: 1rem;
    cursor: default;
    .certificate-image {
      width: 64px;
      height: auto;
      margin-right: 1rem;
    }
    .certificate-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: var(--cl2);
      margin-bottom: 0.5rem;
    }
    .certificate-price {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: var(--cl2);
    }
    .certificate-price-number {
      color: var(--cl4);
    }
    .certificate-time {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: var(--cl2);
    }
    .certificate-time-number {
      color: var(--cl4);
    }
    .certificate-content-wrapper {
      width: 100%;
    }
    .certificate-content {
      word-break: break-all;
      font-size: 14px;
      line-height: 16px;
      color: var(--cl2);
      max-height: 50vh;
      overflow-y: auto;
      margin-top: 1rem;
      padding: 0.85rem;
      background-color: #f2f2f2;
    }
  }
  .back-button {
    cursor: pointer;
    width: 1.5rem;
    background-color: var(--cl1);
    display: grid;
    place-content: center;
    margin-right: 1rem;
    svg {
      color: #fff;
    }
  }
}

.modal-service {
  top: 10vh;
  padding-bottom: 0;
  .register-product-button {
    margin-bottom: 1rem;
  }
  .ant-modal-body {
    padding: 0;
  }
  .slider {
    overflow: visible;
  }
  .slick-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #333333;
    position: absolute;
    top: 50%;
    z-index: 99;
    border-radius: 100%;
    background-color: #333333;
    width: 28px;
    height: 28px;
    color: #fff;
    font-size: 14px;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: #fff;
    outline: none;
    background-color: #333;
  }
  .slick-prev {
    left: -14px;
  }
  .slick-list {
    margin-bottom: 0;
  }
  .slick-next {
    right: -14px;
  }
  .slick-track {
    display: flex;
    width: 100% !important;
  }
  .slider-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    img {
      width: 70vw;
      height: 80vh;
      object-fit: scale-down;
    }
  }
}

.fullpage {
  background-color: #fff;
  .page-content {
    margin-top: 0;
    border-radius: 0;
    margin: auto;
    box-shadow: none;
  }
}

[data-theme='DTrack'] {
  .services-container {
    .page-content {
      .main-services-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        gap: 20px;
        @media (max-width: 1024px) {
          flex-direction: column;
          section {
            padding: 0;
          }
        }

        .main-service {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 20px 0;
          border: 1px solid #e0e0e0;
          padding: 16px 32px;
          border-radius: 10px;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
          &:hover {
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
          }
        }
      }
      .services {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: repeat(4, minmax(180px, 240px));
        justify-content: center;
        gap: 20px;
        margin-top: 5rem;
        //center the grid

        .service-wrapper {
          .service {
            // width: 220px;
            display: grid;
            // grid-template-rows: 1fr;
            // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            // background: #ffffff;
            // padding-top: 1rem;
            // padding-bottom: 1rem;
            height: 100%;
            position: relative;
            .updating {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -300%);
              z-index: 1;
              color: red;
              opacity: 1 !important;
            }
            .service-tag {
              position: absolute;
              top: 0;
              left: 0;
              width: 60px;
              height: 60px;
            }
            .svg-wrapper {
              margin: auto;
            }
            label {
              margin-top: 1rem;
            }
            .ant-checkbox-inner {
              border-radius: 100%;
              width: 20px;
              height: 20px;
              &::after {
                left: 25%;
              }
            }
            .more-info-wrapper {
              display: flex;
              flex: auto;
              align-items: flex-end;
            }
            .more-info {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 1.25rem;
              font-weight: bold;
              text-align: center;
              color: var(--cl4);
              .border-bottom {
                border: 2px solid var(--cl4);
                border-radius: 32px;
                width: 30%;
              }
            }
            .provided-services {
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
              font-weight: normal;
              font-size: 14px;
              color: #4f4f4f;
              .note {
                text-align: left;
              }
              a {
                color: var(--cl1);
                font-style: italic;
                font-weight: bold;
                font-size: 14px;
                color: #4f4f4f;
                text-align: center;
                height: 2.5rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
                display: grid;
                place-items: center;
              }
            }
            .todo-list {
              list-style-type: none;
              li {
                position: relative;
                margin-bottom: 15px;
                &:before {
                  content: ' ';
                  background-image: url('~/assets/svg/checkbox.svg');
                  background-size: 16px 16px;
                  height: 16px;
                  width: 16px;
                  position: absolute;
                  left: -30px;
                  top: 5px;
                }
              }
              .todo {
                display: flex;
                justify-content: space-between;
                &__content {
                  text-align: left;
                }
                &__price {
                  color: var(--cl1);
                  font-style: italic;
                  font-weight: bold;
                }
              }
              .todo-list {
                list-style-type: none;
                li {
                  position: relative;
                  margin-bottom: 15px;
                  &:before {
                    content: ' ';
                    background-image: url('~/assets/svg/checkbox.svg');
                    background-size: 16px 16px;
                    height: 16px;
                    width: 16px;
                    position: absolute;
                    left: -30px;
                    top: 5px;
                  }
                }
                .todo {
                  display: flex;
                  justify-content: space-between;
                  &__content {
                    text-align: left;
                  }
                  &__price {
                    color: var(--cl1);
                    font-weight: bold;
                    font-style: italic;
                  }
                }
              }
            }
            .service-information {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .service-name {
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #4f4f4f;
                height: 3rem;
                max-width: 200px;
                margin: auto;
                margin-top: 1rem;
              }
            }
            .service-desc {
              display: grid;
              place-items: center;
              margin: 1rem 0;
              p {
                margin: unset;
              }
              .service-desc-btn {
                border-radius: 30px;
                font-size: 18px;
                padding: 8px 16px;
                font-weight: bold;
                line-height: 23px;
                text-align: center;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
[data-theme='VietFish'] {
  .services-container {
    .page-content {
      .title {
        color: var(--cl15);
      }
    }
  }

  .fullpage.services-container {
    .page-content {
      margin: auto;
    }
  }

  .ant-btn-primary:enabled {
    border: 1px solid var(--cl15);
    background: var(--cl15);
    color: white;
  }
  .ant-btn-default {
    border: 2px solid var(--cl15);
  }
  .ant-btn-primary:enabled.ant-btn-dangerous {
    background: var(--cl3) !important;
  }
  .ant-btn.ant-btn-dangerous {
    border: 1px solid var(--cl3) !important;
  }
  .ant-btn.ant-btn-default.ant-btn-dangerous {
    color: var(--cl3) !important;
  }
}

@media (max-width: 911px) {
  [data-theme='DTrack'] {
    .services-container {
      .page-content {
        .services {
          grid-template-columns: repeat(3, minmax(180px, 240px));
        }
      }
    }
  }
}

@media (max-width: 576px) {
  [data-theme='DTrack'] {
    .services-container {
      .page-content {
        .services {
          grid-template-columns: repeat(2, minmax(180px, 240px));
        }
      }
    }
  }
}

@media (max-width: 479.98px) {
  .services-container {
    .page-content {
      .services {
        .service-wrapper {
          padding: 1rem 0 0 0;
        }
      }
    }
  }
}
