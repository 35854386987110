.banner-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
  svg {
    path,
    rect {
      fill: var(--cl15);
    }
  }

  .box-menu-item {
    .title-wrapper > .title {
      font-size: 15px;
    }
    z-index: 2;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    transition: none !important;
    .service-name {
      z-index: 2;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 5px;
      text-align: center;
      font-weight: 600 !important;
      padding: 0.25rem;
      font-size: 15px;
    }
    .underline {
      width: 60px;
      height: 3px;
      border-radius: 5px;
      background: white;
      z-index: 2;
    }
  }

  &::before {
    content: ' ';
    background-color: var(--cl15);
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 0;
    transition: all 300ms ease;
    z-index: 1;
    opacity: 0;
  }
  &:hover {
    svg {
      path,
      rect {
        fill: white;
      }
      z-index: 2;
    }
    &::before {
      width: 100%;
      opacity: 1;
    }
    color: white;
  }

  @media (min-width: 768px) {
    min-height: 160px;
    .box-menu-item {
      .svg-wrapper {
        width: 65px;
        height: 65px;
      }
    }
  }
  @media (max-width: 767.98px) {
    min-height: 135px;
    .title-wrapper {
      .underline {
        margin: 0.2rem;
      }
    }
    .box-menu-item {
      padding: 10px 0px;
      .svg-wrapper {
        width: 45px;
        height: 45px;
      }
    }
  }
}
