.ant-upload.ant-upload-select-picture-card {
  background-color: #fff;
  border-color: var(--cl4);
  z-index: 0;
  margin: 0;
  .remove-button {
    z-index: 10;
    background-color: var(--cl5);
    border-radius: 100%;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    .remove-icon {
      fill: #fff;
      width: 8px;
      height: 8px;
      transition: all 0.3ms;
    }
  }
  .remove-button:hover {
    background-color: #fff;
    .remove-icon {
      fill: var(--cl4);
    }
  }
}
.avatar {
  .ant-upload.ant-upload-select-picture-card {
    object-fit: cover;
    width: 7.5rem;
    height: 7.5rem;
  }
}

.banner {
  .ant-upload.ant-upload-select-picture-card {
    object-fit: cover;
    width: 15rem;
    height: 7.5rem;
  }
}

.certificate {
  .ant-upload.ant-upload-select-picture-card {
    object-fit: cover;
    width: 225px;
    height: 317px;
  }
}

.upload-layer {
  height: 100%;
  width: 100%;
  background-color: black;
  position: absolute;
  opacity: 0.5;
}

.upload-btn-wrapper {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f6f6f6;
  border-radius: 4px;
}

.card-with-image {
  .ant-upload.ant-upload-select-picture-card {
    margin-left: -1px;
    margin-right: -1px;
    border: 1px solid var(--cl5);
  }
}

.upload-image-container {
  position: relative;
  height: 100%;
  &:hover::before {
    opacity: 1;
  }
  &:hover .upload-actions-container {
    display: flex;
    gap: 10px;
    color: white;
    z-index: 10;
  }
  &::before {
    position: absolute;
    border-radius: 3px;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    opacity: 0;
    transition: all 0.3s;
    content: ' ';
  }
  .upload-actions-container {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
