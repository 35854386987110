.box-tab-blog {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin-bottom: 1rem;
  .tabs {
    display: flex;
    border-bottom: solid 1px var(--cl9);
    .list {
      display: flex;
      flex-wrap: wrap;
    }
    .title {
      text-transform: uppercase;
      text-align: center;
    }
    .item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      color: #4f4f4f;
      .border-bottom {
        border: 2px solid var(--cl1);
        width: 100%;
        border-radius: 8px;
      }
    }
    .title {
      padding: 0.5rem 1rem;
      background-color: var(--cl7);
      color: #fff;
      font-weight: 900;
      font-size: 16px;
    }
    .item:not(.title):hover a {
      color: var(--cl1);
    }
    .item:not(.title):not(.active):hover a {
      color: var(--cl7);
    }
    .item:not(.title) {
      margin: 0.5rem 1rem 0 1rem;
    }
    .active {
      color: var(--cl1);
    }
    .item .title a:active,
    a:hover {
      color: #fff;
    }
  }
  .tab-content {
    padding: 1rem;
  }
  .view-more {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
  }
  .view-more span {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: var(--cl1);
  }
  .view-more-link {
    width: fit-content;
    display: flex;
    align-items: center;
    font-size: 14px;
    transition: all 0.01s ease;
    -webkit-transition: all 0.01s ease;
    .text {
      margin-right: 0.5rem;
    }
  }
  .view-more-link:hover {
    text-decoration: underline;
    font-weight: 700;
  }

  // .slider {
  //   width: -webkit-fill-available;
  // }

  // .slider-item {
  //   padding: 0.5rem;
  // }

  // .slick-track {
  //   display: flex !important;
  // }

  // .slick-slide {
  //   height: inherit !important;
  // }

  // .slick-slide div {
  //   height: 100%;
  // }

  // .slick-slider .slick-prev {
  //   left: 0 !important;
  //   z-index: 999;
  //   -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  //   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  //   line-height: 30px;
  //   border-radius: 100%;
  //   background-color: rgba(255, 255, 255, 0.8);
  //   width: 2rem;
  //   height: 2rem;
  //   padding: 8px;
  // }

  // .slick-slider .slick-next {
  //   right: 0 !important;
  //   z-index: 999;
  //   -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  //   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  //   line-height: 30px;
  //   border-radius: 100%;
  //   background-color: rgba(255, 255, 255, 0.8);
  //   width: 2rem;
  //   height: 2rem;
  //   padding: 8px;
  // }
}

.blogs-list {
  display: flex;
  color: #000;
  position: relative;
  .main-blog {
    height: 100%;
    background: #ffffff;
    border: 1px solid var(--cl6);
    box-sizing: border-box;
    overflow: hidden;
    .banner {
      height: 237px;
      margin-right: auto;
      cursor: pointer;
      width: 100%;
      object-fit: cover;

      background: #c4c4c4;
    }
    .banner:hover ~ .blog-meta {
      color: var(--cl7);
    }
    &:hover {
      .blog-meta .title {
        color: var(--cl7);
      }

      .banner {
        transform: scale(1.05);
        transition: transform ease-in 0.3s;
      }
    }
    .blog-meta {
      padding: 1rem;
      .title {
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        transition: none;
        -webkit-transition: none;
        color: #4f4f4f;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .description {
        color: #a1a1a1;
        font-size: 12px;
        margin-top: 0.5rem;
        font-weight: bold;
        font-size: 12px;
        color: #4f4f4f;
        .author {
          font-weight: 700;
        }
        .content {
          position: relative;
          height: 28.8px;
          line-height: normal;
          overflow: hidden;
          text-align: justify;

          color: #000000;
          font-weight: normal;
          margin-top: 0.5rem;
          img {
            max-width: 100%;
            max-height: 2rem;
            object-fit: scale-down;
            margin: 0.25rem auto;
          }
        }
        .content::after {
          content: '...';
          background-color: #fff;
          width: 20px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
  .sub-blogs::-webkit-scrollbar {
    width: 0;
  }
  .sub-blogs {
    overflow: auto;
    height: 100%;
    align-content: start;
    .blog-wrapper {
      padding-left: 1rem;
      margin-bottom: 0.5rem;
    }
    .blog-wrapper:nth-child(7),
    .blog-wrapper:nth-child(8) {
      margin-bottom: 0;
    }
    .blog {
      cursor: pointer;
      border: 1px solid var(--cl9);
      display: flex;
      flex-direction: row;
      overflow: hidden;
      flex-wrap: nowrap;
      height: 92px;
    }
    .banner-wrapper {
      width: 140px;
      background: #c4c4c4;
      overflow: hidden;
    }
    .banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .blog:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      .title {
        color: var(--cl7);
      }
    }
    .blog:hover .banner {
      transform: scale(1.05);
      transition: transform ease-in 0.3s;
    }
    .blog-meta {
      font-weight: 600;
      padding: 1rem;
      width: 60%;
      display: flex;
      align-items: center;
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: none;
        -webkit-transition: none;
        font-size: 14px;
        font-weight: 500;
        color: #4f4f4f;
      }
    }
  }
}

.blogs-slide {
  .blog-card:hover > .blog-meta .title {
    color: var(--cl7);
  }
  .blog-card {
    cursor: pointer;
    box-shadow: 0 1px 4px 0 rgba(102, 98, 98, 0.4);
    border-radius: 8px;
    .banner {
      width: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .blog-meta {
      padding: 1rem;
      .title {
        font-weight: 700;
        font-size: 16px;
        color: #000;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: none;
        -webkit-transition: none;
      }
      .description {
        margin-top: 0.5rem;
        color: #a1a1a1;
        font-size: 13px;
        .author {
          font-weight: bold;
        }
        .date {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 991px) {
}

@media only screen and (max-width: 767px) {
}
