.form-input {
  display: flex;
  margin-bottom: 0.5rem;
  .title {
    margin-right: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }
  .input {
    width: 100%;
  }
}
