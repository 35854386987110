.forgot-password-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .login-form {
    display: flex;
    .group {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      justify-content: center;
      color: #fff;
      input {
        width: auto;
        padding: 0 0.25rem;
        font-size: 12px;
        margin: 0.25rem 0;
      }
      .remember-wrapper {
        font-size: 12px;
        color: #fff;
      }
      .remember-wrapper:hover {
        border-color: #fff;
      }
      a:hover {
        color: #fff;
        text-decoration: underline;
      }
      .login-btn {
        padding: 0 0.25rem;
        height: auto;
        font-size: 12px;
        background-color: var(--cl1);
        color: #fff;
        border-color: #fff;
        font-weight: bold;
      }
    }
    .login-btn-wrapper {
      justify-content: flex-start;
      align-items: baseline;
      .error-text {
        margin-bottom: 0.25rem;
        color: #f02849;
        font-weight: 700;
      }
    }
  }
  .login-header-wrapper {
    background-color: var(--cl1);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .login-header {
    font-size: 12px;
    padding: 0.5rem 5rem;
    display: flex;
    justify-content: space-between;
  }
  .login-wrapper {
    background-color: #fff;
  }
  .logo-wrapper {
    display: flex;
    cursor: pointer;
    img {
      width: 64px !important;
    }
    .link-news {
      margin-left: 1.5rem;
      display: grid;
      place-items: center;
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      a {
        padding: 0.5rem;
      }
    }
    .link-news a:hover {
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 5px;
    }
  }
}

.forgot-password-body-wrapper {
  background-color: #fff;
  flex: auto;
  .forgot-password-form {
    margin-top: 4rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-weight: 500;
      font-size: 26px;
      color: var(--cl1);
    }
    .description {
      font-weight: 500;
      font-size: 16px;
      color: var(--cl2);
      text-align: center;
    }
    input {
      width: 30%;
      margin: 4rem 0;
      padding: 0.5rem;
      font-size: 16px;
      font-weight: 500;
    }
    button {
      font-weight: bold;
      width: 10rem;
    }
    .back-button {
      margin-top: 2rem;
    }
  }
}

.fp-error {
  font-size: 1rem;
  line-height: 12px;
  height: 24px;
}
