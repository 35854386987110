.consultation-form {
  max-width: 704px;
  margin-left: auto;
  margin-right: auto;
  .label {
    font-size: 16px;
    display: inline-block;
    margin-bottom: 8px;
  }
  .ant-form-item-required::after {
    content: '' !important;
  }
  .ant-form-item-has-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  input,
  textarea {
    background: #e0e0e0 !important;
    border-radius: 1rem;
    border: none;
    &:focus {
      border: none;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 14px;
  }
}
