//display
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.inline-flex {
  display: inline-flex;
}
.flex {
  display: flex;
}
//end of display

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.justify-center {
  justify-content: center;
}
.justify-around {
  justify-content: space-around;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.self-center {
  align-self: center;
}
.self-start {
  align-self: self-start;
}
.self-end {
  align-self: self-end;
}
.border-box {
  box-sizing: border-box;
}
.cursor-pointer {
  cursor: pointer;
}
.font-light {
  font-weight: 300;
}
font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
//dimension
.w-64 {
  width: 16rem;
}
.w-full {
  width: 100%;
}
.h-full {
  width: 100%;
}
.w-auto {
  width: auto;
}
.h-auto {
  height: auto;
}
.w-screen {
  width: 100vw;
}
.h-screen {
  width: 100vh;
}
//end of dimension

// spacing
.gap-5 {
  gap: 1.25rem;
}
.gap-x-0 {
  column-gap: 0;
}
.gap-x-5 {
  column-gap: 1.25rem;
}
.gap-x-6 {
  column-gap: 1.5rem;
}
.gap-x-8 {
  column-gap: 2rem;
}
.gap-x-10 {
  column-gap: 2.5rem;
}
.gap-x-16 {
  column-gap: 0rem;
}
.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-40 {
  margin-bottom: 10rem;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-5 {
  padding: 1.25rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 1.25rem;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
// end of spacing

//position
.top-4\/7 {
  top: 57.1428571%;
}
//end of position

//text
.text-black {
  color: #2d2e32;
}
.text-white {
  color: white;
}
.text-base {
  font-size: 14px;
  line-height: 17px;
  &--note {
    font-size: 12px;
    line-height: 17px;
  }
  &--small {
    font-size: 13px;
    line-height: 15px;
  }
  &--bigger {
    font-size: 16px;
    line-height: 19px;
  }
  &--large {
    font-size: 18px;
    line-height: 22px;
  }
  &--hugh {
    font-size: 20px;
    line-height: 24px;
  }
}
.text-title {
  font-size: 24px;
  line-height: 28px;
  &--bigger {
    font-size: 32px;
    line-height: 37px;
  }
  &--large {
    font-size: 36px;
    line-height: 42px;
  }
  &--hugh {
    font-size: 48px;
    line-height: 56px;
  }
}
.text-2xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-left {
  text-align: left;
}
.text-justify {
  text-align: justify;
}
.text-center {
  text-align: center;
}
.text-green {
  color: var(--green);
}
.uppercase {
  text-transform: uppercase;
}
//end of text
//background
.bg-white {
  background: white;
}
.bg-lightgreen {
  background: var(--lightgreen);
}
.bg-gray {
  background: var(--gray);
}
.bg-green {
  background: var(--green);
}
//end of background

//border
.border {
  border: 1px solid #2d2e32;
}
.border-green {
  border: 1px solid var(--green);
}
//end of border

//rounded
.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}
//rounded

//shadow
.shadow {
  --shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--shadow);
}
.shadow-md {
  --shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--shadow);
}
//end of shadow

//transition
.transition-none {
  transition-property: none;
}
.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
//end of transition
//duration
.duration-1000 {
  transition-duration: 1000ms;
}
//end of duration

.overflow-hidden {
  overflow: hidden;
}

@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .md\:-left-1\/7 {
    left: -14.2857143%;
  }
}

@media (min-width: 992px) {
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 1280px) {
  .xl\:-left-2\/7 {
    left: -14.2857143%;
  }
}
