.account-setup {
  .page-title {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    display: flex;
    align-items: center;
    .line {
      width: 5rem;
      border-top: 6px solid var(--cl6);
      border-radius: 8px;
      margin-left: 1.5rem;
    }
  }
  .page-content {
    margin-top: 1rem;
    .left-wrapper {
      .left {
        padding-top: 1rem;
        .item {
          padding: 0.25rem 1.25rem;
          border-radius: 23px;
          cursor: pointer;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: var(--cl2);
          font-weight: 500;
          svg {
            margin-right: 0.5rem;
          }
        }
        .active {
          transition: none;
          color: #fff;
          font-weight: bold;
          background-color: var(--cl1);
        }
        .item:not(:first-child) {
          margin-top: 1rem;
        }
      }
    }
    .right-wrapper {
      padding-left: 1rem;
      .right {
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        .sub-menus {
          display: flex;
          border-bottom: 1px solid var(--cl6);
          .item {
            padding: 2rem 1rem 0 1rem;
            cursor: pointer;
            color: var(--cl5);
            font-weight: 500;
            .active {
              color: var(--cl1);
              font-weight: bold;
            }
            .border-bottom {
              height: 4px;
              width: 2rem;
              border-radius: 8px;
              &.active {
                background-color: var(--cl1);
              }
            }
          }
          .item:not(:first-child) {
            margin-left: 2rem;
          }
        }
      }
    }
  }
  .content {
    padding: 1rem 1rem 0 1rem;
  }
}

.producer-information {
  .company-image {
    margin-right: 0.5rem;
  }
  .certificate-image {
    padding: 0.25rem;
  }
  .download-qr-code-button {
    margin-right: 0.5rem;
  }
  .information-form {
    .certificates {
      .list-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .video-url-wrapper {
      .group:not(:first-child) {
        margin-top: 0.5rem;
      }
    }
    .news-url-wrapper {
      .group:not(:first-child) {
        margin-top: 0.5rem;
      }
    }
    .distributed-channel {
      .url {
        padding-left: 0.5rem;
      }
      .group:not(:first-child) {
        margin-top: 0.5rem;
      }
    }
    .company-images {
      display: flex;
      justify-content: flex-start;
    }
    .company-image-count {
      color: var(--cl1);
    }
    .ant-form-item-explain,
    .ant-form-item-extra {
      font-size: 12px;
    }
    .ant-form-item-label > label {
      font-size: 13px !important;
    }
    input {
      font-size: 12px;
      padding: 0.5rem;
    }
    .ant-select-selector {
      padding: 0.1rem 0.5rem;
      font-size: 12px;
    }
    .ant-input-affix-wrapper {
      padding: 0;
    }
    textarea.ant-input {
      font-size: 12px;
    }
    .ant-form-item-label > label {
      font-size: 14px;
    }
    .ant-radio-button-wrapper {
      height: 100%;
      border-radius: 8px;
      border-left-width: 1px;
      width: 7.5rem;
      padding: 1rem;
      width: 160px;
      .business-type-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .name {
          color: var(--cl2);
          font-size: 12px;
        }
      }
      .business-type-item:hover {
        .name {
          color: var(--cl1);
        }
      }
    }
    .ant-radio-button-wrapper:not(:first-child) {
      margin-left: 1rem;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      width: 0;
    }
  }
}

.remove-button-wrapper {
  display: flex;
  align-items: center;
}
.remove-button {
  box-shadow: none;
  border: none;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: none;
    width: 60% !important;
  }
}

.plus-button-wrapper {
  display: flex;
  align-items: center;
}
.plus-button {
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: none;
    width: 60% !important;
  }
}
.add-image-button-wrapper {
  padding-top: 1rem;
}
.add-image-button {
  display: flex;
  align-items: center;
  font-size: 12px;
  height: fit-content;
  padding: 2px 7px;
  svg {
    transition: none;
    margin-right: 0.25rem;
  }
}

.producer-qr-code {
  .qr-code-tips {
    color: var(--cl1);
  }
  .producer-qr-code-left {
    padding-right: 3.5rem;
  }
  .producer-qr-code-right {
    margin-top: 4rem;
    padding-right: 3.5rem;
  }
  .title {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 24px;
    color: var(--cl1);
  }
  .description {
    margin-bottom: 0.5rem;
    color: var(--cl2);
  }
  .qr-wrapper {
    margin-top: 1.5rem;
    .title {
      font-size: 14px;
      color: var(--cl2);
    }
    .edit-qrcode {
      margin-left: 1rem;
      margin-right: 0.25rem;
      color: #fff;
      background-color: var(--cl1);
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      border-radius: 12px;
      font-size: 12px;
    }
    .qr-code-wrapper {
      margin: 1rem 0;
      display: flex;
      justify-content: center;
      .qr-code {
        width: 256px;
        padding: 2rem;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        canvas {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
  .link-wrapper {
    margin-top: 1.5rem;
    margin-bottom: 3.5rem;
    .title {
      font-size: 14px;
      color: var(--cl2);
    }
    .ant-input-group-addon {
      background-color: var(--cl2);
    }
    button {
      background-color: var(--cl2);
      border-color: var(--cl2);
    }
  }
}

.account-information {
  padding: 1rem 1rem 0 1rem;
  .form-title {
    font-weight: bold;
    font-size: 16px;
    color: var(--cl1);
    margin-bottom: 10px;
  }
}

.change-password {
  padding: 1rem 1rem 0 1rem;
}

.information-form {
  label {
    font-size: 13px;
  }
  input {
    font-size: 12px;
    padding: 0.5rem;
  }
  .footer {
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 1px solid var(--cl6);
    .save-button {
      margin-right: 1rem;
      .ant-btn-loading-icon {
        padding-left: 2rem;
      }
      .title {
        padding-right: 2rem;
      }
    }
    .save-button:not(.ant-btn-loading) {
      .title {
        padding-left: 2rem;
      }
    }
  }
}

.edit-qrcode-form {
  .color-picker {
    margin-top: 0.5rem;
  }
  .download-wrapper {
    display: flex;
    justify-content: center;
  }
  .qr-code {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
  .field-item {
    margin-bottom: 0.5rem;
    .include-logo-checkbox {
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #d9d9d9 !important;
      }
      .ant-checkbox:hover {
        .ant-checkbox-input + .ant-checkbox-inner {
          border-color: #d9d9d9 !important;
        }
      }
    }
    .title {
      margin-right: 0.5rem;
    }
    .title-wrapper {
      display: flex;
    }
    .picked-color-wrapper {
      width: fit-content;
      padding: 0.2rem;
      border: 1px solid #000;
    }
    .picked-color {
      width: 2rem;
      height: 1rem;
    }
    input {
      width: 50%;
    }
  }
  .qrcode-warning {
    font-style: italic;
    font-size: 12px;
    font-weight: 700;
  }
}

.modal-qrcode {
  width: 50vw !important;
}

.modal-new-account-intro {
  min-width: 30vw;
  .ant-modal-content {
    border-radius: 24px;
  }
  .new-account-intro-image {
    height: 315px;
  }
  .slider-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      font-weight: 500;
      font-size: 24px;
      text-align: center;
      color: var(--cl1);
    }
    .description {
      font-size: 16px;
      text-align: center;
      color: #4f4f4f;
      font-weight: normal;
    }
  }
  .slick-slider .slick-prev {
    left: 0 !important;
    z-index: 999;
    width: auto;
    color: var(--cl1);
    top: 97%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .slick-slider .slick-next {
    right: 0 !important;
    z-index: 999;
    width: auto;
    color: var(--cl1);
    top: 97%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .finish-intro-button {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 999;
    border-radius: 15px;
  }
  .slick-list {
    margin-bottom: 2rem;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-slide img {
    transform: scale(0.85);
  }
}
@media (max-width: 768px) {
  .modal-qrcode {
    width: 100vw !important;
  }
  .producer-qr-code-left {
    padding-right: 0 !important;
  }
  .producer-qr-code-right {
    margin-top: 1rem !important;
    padding-right: 0 !important;
  }
}
