.footer {
  padding-top: 2rem;
  background: white;
  a:hover {
    color: var(--cl1);
  }
  .footer__topline {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .footer__logo {
    margin-bottom: 1.875rem;
  }
  .footer__content {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    .footer__content__description {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;

      .description__group {
        img {
          max-height: 25px;
          object-fit: contain;
        }
        margin-top: 10px;
        display: flex;
        gap: 1rem;
        padding: 0.5rem 0;
        span {
          align-self: center;
        }
      }

      &:last-child {
        .description__group {
          justify-content: center;

          img {
            max-height: 75px;
            margin-top: -10px;

            &:hover {
              opacity: 0.95;
            }
          }
        }
      }
    }
    .footer__content__information__title {
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      margin-bottom: 0.75rem;
    }
    .footer__copyright {
      padding-left: 2.5rem;
      color: #828282;
      font-size: 13px;
      line-height: 14px;
    }
  }
}
