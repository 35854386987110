.livestream-guide-steps {
  .anticon > svg {
    fill: var(--cl1);
  }
  .ant-steps-item-finish {
    .ant-steps-item-title:after {
      background-color: var(--cl1) !important;
    }
  }
}
