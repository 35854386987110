.admin-account-setup {
  .topbar {
    display: flex;
    justify-content: space-between;
    .page-title {
      font-weight: 500;
      font-size: 24px;
      color: #000000;
      display: flex;
      align-items: center;
      .line {
        width: 5rem;
        border-top: 6px solid var(--cl6);
        border-radius: 8px;
        margin-left: 1.5rem;
      }
    }
  }
  .page-content {
    margin-top: 1rem;
    .left-wrapper {
      .left {
        padding-top: 1rem;
        .item {
          padding: 0.25rem 1.25rem;
          border-radius: 23px;
          cursor: pointer;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          color: var(--cl2);
          font-weight: 500;
          svg {
            margin-right: 0.5rem;
          }
        }
        .active {
          transition: none;
          color: #fff;
          font-weight: bold;
          background-color: var(--cl1);
        }
        .item:not(:first-child) {
          margin-top: 1rem;
        }
      }
    }
    .right-wrapper {
      padding-left: 0;
      .right {
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        .sub-menus {
          display: flex;
          border-bottom: 1px solid var(--cl6);
          .item {
            padding: 2rem 1rem 0 1rem;
            cursor: pointer;
            color: var(--cl5);
            font-weight: 500;
            .active {
              color: var(--cl1);
              font-weight: bold;
            }
            .border-bottom {
              height: 4px;
              width: 2rem;
              border-radius: 8px;
              &.active {
                background-color: var(--cl1);
              }
            }
          }
          .item:not(:first-child) {
            margin-left: 2rem;
          }
        }
      }
    }
  }
  .content {
    padding: 1rem 1rem 0 1rem;
  }
}
