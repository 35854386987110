.reset-password-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .login-header-wrapper {
    background-color: var(--cl1);
  }
  .login-header {
    font-size: 12px;
    padding: 0.5rem 5rem;
    display: flex;
    justify-content: space-between;
  }
  .login-wrapper {
    background-color: #fff;
    cursor: pointer;
  }
  .logo-wrapper {
    display: flex;
  }
}

.reset-password-body-wrapper {
  background-color: #fff;
  flex: auto;
  .reset-password-form {
    margin-top: 4rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .new-password-form {
      margin: 4rem 0;
      input {
        width: 100%;
        margin: 0.5rem 0;
        padding: 0.5rem;
      }
      .success-message {
        text-align: center;
        color: var(--cl1);
      }
      .error-message {
        text-align: center;
        color: var(--cl3);
      }
    }
    .title {
      font-weight: 500;
      font-size: 26px;
      color: var(--cl1);
    }
    .description {
      font-weight: 500;
      font-size: 16px;
      color: var(--cl2);
      text-align: center;
    }
    button {
      font-weight: bold;
      width: 10rem;
    }
    .back-button {
      margin-top: 2rem;
    }
  }
}
